import { booking } from "@/api/booking.js";
import { departureScheduleApi } from "@/api/departureSchedule.js";
import dayjs from "dayjs";
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";

export default {
  components: { DatePick },
  data: () => ({
    timeStart: dayjs().format("YYYY-MM-DD"),
    departureScheduleId: null,
    listBooking: [],
    isLoading: false,
    isLoadingListDepartureSchedule: false,
    listStatus: [
      {
        id: "CANCEL",
        title: "Đã huỷ",
      },
      {
        id: "ONCAR",
        title: "Đã đón",
      },
      {
        id: "BOOKED",
        title: "Đã đặt",
      },
      {
        id: "COMPLETED",
        title: "Hoàn thành",
      },
    ],
  }),
  created() {
    this.getListDepartureSchedule(this.timeStart);
  },
  watch: {
    timeStart(value) {
      this.getListDepartureSchedule(value);
    },
    departureScheduleId() {
      this.getListBooking();
    },
  },
  methods: {
    disableDate(date) {
      const currentDate = new Date();
      let featureDate = new Date();
      featureDate.setDate(currentDate.getDate() + 7);

      return date < currentDate || date > featureDate;
    },
    async getListDepartureSchedule(starTime) {
      this.isLoadingListDepartureSchedule = true;

      let params = {
        starTime: starTime,
      };

      await departureScheduleApi
        .getListDepartureSchedule(params)
        .then((res) => {
          if (res.length) {
            this.listDepartureSchedule = res;
            this.departureScheduleId = res[0].ID;
          } else {
            this.listDepartureSchedule = [
              {
                ID: -1,
                post_title: "Không có chuyến xe nào",
              },
            ];
            this.departureScheduleId = -1;
          }
        });

      // Render lại select
      this.isLoadingListDepartureSchedule = false;
      // Tắt loading
    },
    async getListBooking() {
      let body = {
        departureScheduleId: this.departureScheduleId,
      };
      await booking.getListBooking(body).then((res) => {
        this.listBooking = res;
      });

      // Tắt loading
    },
    async cancelBooking(Id) {
      const loading = this.$vs.loading();
      this.isLoading = true;
      let params = {
        userId: JSON.parse(localStorage.getItem("userData")).id,
        bookingId: Id,
      };

      await booking.cancelBooking(params).then((res) => {
        if (res.code == "SUCCESS") {
          this.$vs.notification({
            color: "success",
            position: "top-right",
            duration: "2000",
            title: "Huỷ ghế thành công !",
          });
          this.getListBooking();
        }
      });
      this.isLoading = false;
      // Tắt loading
      loading.close();
    },
    getNameListDepartureSchedule(item) {
      return dayjs(item.timeStart).format("HH:mm") + " - " + item.post_title;
    },
    editBooking(id) {
      return this.$router.push({
        name: "editBooking",
        params: {
          id: id,
        },
      });
    },
    totalAmount() {
      let total = 0;
      this.listBooking.forEach((element) => {
        if (element.acf.status == "ONCAR" && element.acf.amount) {
          total = total + parseInt(element.acf.amount);
        }
      });
      return this.priceFormat(total);
    },
  },
};
